<template lang="html">
  <BreakLeave v-if="cantleave"></BreakLeave>
</template>

<script>
  import BreakLeave from '../../BreakLeave'
  export default {
    components: {
      BreakLeave,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        cantleave: true,
      }
    },
    created() {

    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          cancel: this.cancel,
          close: this.close,
        }
      })
    },
    destroyed() {
    },
    methods: {
      cancel() {
        if (confirm("确定退出吗，未保存的内容将丢失")) {
          this.close()
        } else {
          return
        }
      },
      close() {
        this.cantleave = false
        this.$nextTick(() => {
          if (this.config && this.config.closeName) {
            this.$router.push({ name: this.config.closeName })
          } else {
            this.$router.go(-1)
          }
        })
      },
    },
  }
</script>

<style lang="css">
</style>
